





















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import GenproxBreadcrumbs from './GenproxBreadcrumbs.vue';

@Component({
  components: { GenproxBreadcrumbs }
})
export default class GenproxPageHeading extends Vue {
  @Prop({ default: null }) breadcrumbsTitle: string;
  @Prop() title!: string;
  @Prop() description!: string;

}
