












import _ from 'lodash';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

@Component
export default class GenproxBreadcrumbs extends Vue {
  @Prop({ default: null }) title: string;
  items: any[] = []

  get formattedItems() {
    let formattedItems = _.cloneDeep(this.items)

    formattedItems = formattedItems.map((el: any) => {
      if (el?.text === ':title') {
        el.text = this.title || el?.text
      }

      if (el?.text === ':currentContext') {
        el.text = this.$route?.path?.includes('/fund-manager') ? 'AIFM' : this.$route?.path?.includes('/company') ? 'Company' : 'Fund'
        el.href = this.$route?.path?.includes('/fund-manager') ? '/fund-manager/dashboard' : this.$route?.path?.includes('/company') ? '/company/dashboard' : '/fund/dashboard'
      }

      if (el?.href?.includes(':currentContext')) {
        const currentContext = this.$route?.path?.includes('/fund-manager') ? 'fund-manager' : this.$route?.path?.includes('/company') ? 'company' : 'fund'
        el.href = el.href.replaceAll(':currentContext', currentContext)
      }

      return el
    })

    return formattedItems
  }

  mounted() {
    this.items = this.$route?.meta?.breadcrumbs || []
  }
}
