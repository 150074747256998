var render = function () {
  var _vm$$slots, _vm$$slots2, _vm$$slots3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gn-page-heading"
  }, [_c('GenproxBreadcrumbs', {
    attrs: {
      "title": _vm.breadcrumbsTitle
    }
  }), _c('div', {
    staticClass: "gn-page-heading__inner"
  }, [_c('h1', {
    staticClass: "gn-page-heading__header",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }), _vm.description ? _c('p', {
    staticClass: "gn-page-heading__desc",
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  }) : _vm._e(), !!((_vm$$slots = _vm.$slots) !== null && _vm$$slots !== void 0 && _vm$$slots.actions) || !!((_vm$$slots2 = _vm.$slots) !== null && _vm$$slots2 !== void 0 && _vm$$slots2.actionsLeft) || !!((_vm$$slots3 = _vm.$slots) !== null && _vm$$slots3 !== void 0 && _vm$$slots3.actionsRight) ? _c('div', {
    staticClass: "gn-page-heading__actions"
  }, [_vm._t("actions", function () {
    var _vm$$slots4, _vm$$slots5;

    return [!!((_vm$$slots4 = _vm.$slots) !== null && _vm$$slots4 !== void 0 && _vm$$slots4.actionsLeft) ? _c('div', {
      staticClass: "gn-page-heading__actions-left"
    }, [_vm._t("actionsLeft")], 2) : _vm._e(), !!((_vm$$slots5 = _vm.$slots) !== null && _vm$$slots5 !== void 0 && _vm$$slots5.actionsRight) ? _c('div', {
      staticClass: "gn-page-heading__actions-right"
    }, [_vm._t("actionsRight")], 2) : _vm._e()];
  })], 2) : _vm._e()]), _vm._t("additionalContent")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }